/* global Pikaday, guild, onmount */
// Load all project dependencies
require('./bootstrap');
require('./utils');
// require('./typekit_import');
require('jquery.repeater');


$.extend($.fn.dataTable.defaults, {
  processing: true,
  info: true,
  autoWidth: false,
  stateSave: true,
  // eslint-disable-next-line
  preDrawCallback: function preDrawCallback(settings) {
    $('.dataTables_length select').addClass('custom-select');
  },
});

$.fn.select2.defaults.set('theme', 'bootstrap4');


$(document).ready(() => {

  $('[data-toggle="tooltip"]').tooltip();

  /**
   * Select2 global setup
   */

  $('.select2').select2({
    ajax: {
      data: params => guild.select2AjaxData(params),
    },
    processResults(data) {
      // eslint-disable-next-line
      console.log(data);
      return data;
    },
  });

  $('.select2-noajax').select2();

  /**
   * Document management
   */

  // Document toggle buttons for editing document files
  // We only need to apply this to initial elements.
  $('.list-group-item--file').each(function eachFileItem() {
    const documentToggleButton = $('.toggle-file', this);
    const items = $('.file__toggle > *', this);
    let index = 0;

    documentToggleButton.on('click', () => {
      $(items[index]).toggleClass('d-none');
      index += 1;
      if (index === items.length) {
        index = 0;
      }
      $(items[index]).toggleClass('d-none');
    });
  });

  // https://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/
  $('.input-group-btn--file input').each(function eachInputGroupBtnFile() {
    const $input = $(this);
    const $label = $input.next('label');
    const labelVal = $label.html();

    $input.on('change', function inputGroupBtnFileOnChange(e) {
      let fileName = '';

      if (this.files && this.files.length > 1) { fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length); } else if (e.target.value) { fileName = e.target.value.split('\\').pop(); }

      if (fileName) { $label.find('span').html(fileName); } else { $label.html(labelVal); }
    });

    // Firefox bug fix
    $input
      .on('focus', () => { $input.addClass('has-focus'); })
      .on('blur', () => { $input.removeClass('has-focus'); });
  });

  const mediaRepeaterElement = $('.media-repeater');
  if (mediaRepeaterElement.length > 0) {
    mediaRepeaterElement.repeater({
      show() {
        $('.input-group--show', this).remove();
        $('.input-group--upload', this).removeClass('d-none');
        $('.toggle-file', this).toggleClass('toggle-file disabled');
        // Update all labels and inputs with the dynamically generated names from the input.
        // Animate slide-down
        $(this).slideDown()
          .promise()
          .then(guild.updateRepeaterItems.bind(this, $(this).closest('[data-repeater-list]')));
      },
      hide(deleteElement) {
        $(this).slideUp()
          .promise()
          .then(deleteElement)
          .done(guild.updateRepeaterItems.bind(this, $(this).closest('[data-repeater-list]')));
      },
      ready(setIndexes) {
        setIndexes();
        guild.updateRepeaterItems($('[data-repeater-list]', '.staff-repeater'));
      },
    });
  }
  onmount();
});

/**
 * Pikaday global setup
 */

onmount('.date-control', function mountPikaday() {
  // eslint-disable-next-line
  let pikaday = new Pikaday({
    field: this,
  });
});


/**
 --------------------------------------------------
 VueJs Below
 --------------------------------------------------
 * */

import moment from 'moment';
import 'moment/min/locales.min';

window.Moment = moment;

// let Select2 = require('./components/select2/select2wrapper');
// window.Select2 = Select2.default;

let Multiselect = require('./vendor/vue-multiselect/vue-multiselect.min.js');

window.Multiselect = Multiselect.default;

let DatamaskDecimal = require('./components/data-masks/decimal');

window.DatamaskDecimal = DatamaskDecimal.default;

/**
 * Initilize Filters
 * */
// Translate: usage {{ 'String' | translate }} ,uses files from ./lang.
Vue.filter('translate', function(val){
  const language = (Laravel.language ? require('./lang/' + Laravel.language + '.json') : require('./lang/en.json'));

  if(language[val]) {
    return language[val];
  } else {
    return val
  }
});

/**
 * Create a fresh Vue application instance and attach it to the page.
 * */
var Bus = new Vue({});
window.Bus = Bus; // Now we bind our event bus to window, so its global.

/**
 * Components, only initilize components if they exist on requested page.
 * Good practive if blades are main render component and vuejs augments them.
 */
// Report Builder Index ( Report Format Creation )
if( document.querySelector('[data-vue="report-builder"]') ) {
  const ReportBuilder = new Vue({
    el: '[data-vue="report-builder"]',
    components: {
      'report-builder': require('./components/reportBuilder/index.vue').default,
    }
  });
}
