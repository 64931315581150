/**
* Manage Errors
* Simple error managment, add, check / remove errors when called
**/
class Errors {
  constructor(){
    this.errors = {};
  }

  get(field){
    if(this.errors[field]){
      return this.errors[field][0];
    }
  }

  any(){
    return Object.keys(this.errors).length > 0;
  }

  has(field){
    return this.errors.hasOwnProperty(field)
  }

  record(errors){
    this.errors = errors;
  }

  clear(field){
    delete this.errors[field]
  }
}

export default Errors;
