<template>
  <div class="row" >
    <div class="col-md-12">
      <div class="page-sub-title">
        {{ 'Build a New Report' | translate }} - {{ modelValue.name | translate }}
      </div>
      <!--<h1 class="text-center">{{ 'Build a New Report' | translate }} - {{ modelValue.name | translate }}</h1>-->
      <div class="hdlink-tl">
        <a @click.prevent="goBack()" class="btn btn-link">◀ {{ 'Back' | translate }}</a>
      </div>
    </div>

    <div class="col-md-12"><hr></div>

    <div class="col-md-12">
      <vue-loader :show="loading.isloading" :message="loading.message"></vue-loader>

    <!-- Avalible Fields -->

      <div v-if="!loading.isloading" class="row">
        <div class="col-md-5">

          <p class="model-title">{{ 'Available Fields' | translate }}</p>
          <p class="model-description">{{ 'af.description' | translate }}</p>

          <div class="panel-group" id="fieldList" role="tablist" aria-multiselectable="true">
            <div v-for="(group, key) in grouped" v-if="group.options.length" class="panel panel-default separate-panel">
              <div class="panel-heading" role="tab" :id="'heading' + group.model">
                <h4 class="panel-title">
                  <a v-if="modelValue.name.indexOf('-') !== -1 && group.model == modelValue.model" role="button" :class="['collapse-handle',{'collapsed' : group.model !== modelValue.model}]" data-toggle="collapse" data-parent="#fieldList" :href="'#collapse-' + group.model" :aria-expanded="(group.model == modelValue.model ? 'true' : 'false')" aria-controls="collapseOne">
                    {{ modelValue.name.substring(modelValue.name.indexOf('-')+1) }} <small v-if="group.model == modelValue.model" class="pl-rule">{{ 'at least one item must be selected' | translate }}</small> <span></span>
                  </a>
                  <a v-if="modelValue.name.indexOf('-') === -1 || group.model != modelValue.model" role="button" :class="['collapse-handle',{'collapsed' : group.model !== modelValue.model}]" data-toggle="collapse" data-parent="#fieldList" :href="'#collapse-' + group.model" :aria-expanded="(group.model == modelValue.model ? 'true' : 'false')" aria-controls="collapseOne">
                    {{ group.model }} <small v-if="group.model == modelValue.model" class="pl-rule">{{ 'at least one item must be selected' | translate }}</small> <span></span>
                  </a>
                </h4>
              </div>
              <div :id="'collapse-' + group.model" :class="['panel-collapse','collapse',{'in' : group.model == modelValue.model},{'show' : group.model == modelValue.model}]" role="tabpanel" :aria-labelledby="'heading' + group.model">
                <div :class="['list-group']">
                  <button v-for="(option, index) in group.options" v-if="option.display" type="button" @click.prevent="setField(index,key)" class="list-group-item">{{ option.name | translate }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Selected Fields -->
        <div class="col-md-5 col-md-offset-1">
          <p class="model-title">
            {{ 'My Report Fields' | translate }}
          </p>
          <p class="model-description">{{ 'myf.description' | translate }}<p>
          <ul class="list-group lg-close">
            <li v-for="(option, index) in dataFields.value" :key="index" draggable="true" @dragstart="dragStart(index, $event)" @dragover.prevent @dragend="dragEnd" @drop="dragFinish(index, $event)" class="list-group-item list-item-handdle">
              <span class="lg-title">{{ option.name | translate }}</span>
              <button @click.prevent="removeField(index)" class="float-right showcursor btn btn-sm btn-danger pt-2">
                <i class="far fa-times-circle fa-2x"></i>
              </button>
              <div class="clearfix"></div>
            </li>
          </ul>
          <div>
            <button v-if="hasFields" :disabled="!dataFields.value.length" @click.prevent="dataFields.finished = 1" type="button" :class="['btn btn-primary btn-lg']">{{ 'Next Step' | translate }}</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<style>

</style>

<script>
/**
<div class="list-group">
  <button v-for="(option, index) in options" v-if="option.display" type="button" @click.prevent="setField(index)" class="list-group-item">{{ option.name | translate }}</button>
</div>
**/
export default{
    template: '<report-fields></report-fields>',
    props: ['dataFields','modelValue','validation'],
    data(){
        return{
            options: [],
            grouped: [],
            tempVar: {},
            dragging: -1,
            loading: {
              isloading: false,
              message: '...Loading Fields'
            }
        }
    },
    created(){
      history.pushState(null, null, location.href)
    },
    mounted(){
      this.scrollToTop()
      this.fetch()
    },
    computed:{
      isDragging() {
        return this.dragging > -1
      },
      hasFields(){
        // At least one from parent member selected
        let vm = this

        if( vm.dataFields.value.length ){
          let enableContinue = false

          for (var i=0; i < vm.dataFields.value.length; i++) {
            if(vm.modelValue.model == vm.dataFields.value[i].model){
                enableContinue = true
            }
          }

          return enableContinue
        }else{
          return false
        }
      }
    },
    watch:{

    },
    methods:{
      scrollToTop(){
        $('html,body').stop().animate({
          scrollTop: 0
        }, 'slow', 'swing');
      },
      fetch: _.throttle( function () {
          let vm = this,
              tmpModel = vm.modelValue.model

          vm.$set(vm.loading,'isloading',true)
          vm.$set(vm.loading,'message','...Loading Fields')
          //Clear Data
          vm.$set(vm.grouped,[])

          const tempObject = {
                  model: tmpModel,
                  options: []
                }

          // Get Actual data via axios
          vm.axios.get('/report-generator/reportable-fields/' + tmpModel)
          .then( response => {
            tempObject.options.push.apply(tempObject.options, response.data.filters)
            vm.grouped.push(tempObject)

            if(response.data.relationships){
              vm.fetchRelations()
            }else{
              if(vm.dataFields.value.length){
                vm.setExisting()
              }else{
                //vm.preSelect()
                vm.$set(vm.loading,'isloading',false)
              }
            }
          }).catch( error => {
            vm.validation.record(error.response.data)
            vm.$set(vm.loading,'isloading',false)
          });
      },400 ),
      fetchRelations: _.throttle( function () {
        let vm = this,
            tmpModel = vm.modelValue.model

        vm.$set(vm.loading,'message','...Loading Relationships')
        // Get relationships
        vm.axios.get('/report-generator/all-related-fields/' + tmpModel)
        .then( response => {
          //console.log('%c Relationship fields', 'color: blue; font-weight: bold;')
          //console.log(response.data)

          Object.entries(response.data).forEach(([key, val]) => {
            const tempObject = {
                    model: val.model,
                    options: []
                  }

            tempObject.options.push.apply(tempObject.options, val.filters)
            vm.grouped.push(tempObject)
          })

          if(vm.dataFields.value.length){
            vm.setExisting()
          }else{
            //vm.preSelect()
            vm.$set(vm.loading,'isloading',false)
          }
        }).catch( error => {
          vm.validation.record(error.response.data)
          vm.$set(vm.loading,'isloading',false)
        })
      }),
      setExisting(){
        /**
        * Configure results for Exisitng Values
        **/
        let vm = this

        vm.$set(vm.loading,'message','...Configuring Options')

        for (var i=0; i < vm.dataFields.value.length; i++) {
          const fullR = vm.dataFields.value[i].fullRelationship
          const model = vm.dataFields.value[i].model

          const key = vm.grouped.indexOf( vm.grouped.find(function (obj) { return obj.model === model }) )
          if( key > -1 ){
            const updateIndex = vm.grouped[key].options.indexOf(vm.grouped[key].options.find(function (obj) { return obj.fullRelationship === fullR }))

            if( updateIndex > -1 ){
              vm.$set(vm.grouped[key].options[updateIndex], 'display', false)
            }
          }
        }

        vm.$set(vm.loading,'isloading',false)
      },
      /**
      * Pre Select first field if new form
      **/
      preSelect(){
        let vm = this

        // Only PreSelect if value is empty
        if( !vm.dataFields.value.length ){
          const thisOption = vm.grouped[0].options[0]
          vm.dataFields.value.push(thisOption)
          vm.$set(vm.grouped[0].options[0], 'display', false)
        }
      },
      setField(index,key){
          let vm = this,
              //thisOption = vm.options[index]
              thisOption = vm.grouped[key].options[index]

          // Add selected option to values
          vm.dataFields.value.push(thisOption)

          // Hide selected option
          vm.$set(vm.grouped[key].options[index], 'display', false)
      },
      removeField(index){

        let vm = this,
            thisOption = vm.dataFields.value[index],
            /**
            * Check as same not exact, and in case of specific object seach by identifyer,
            * edit or returning to page the object as not exact as options are reloaded.
            **/
            key = vm.grouped.indexOf( vm.grouped.find(function (obj) { return obj.model == thisOption.model }) ),
            updateIndex = vm.grouped[key].options.indexOf( vm.grouped[key].options.find(function (obj) { return obj.fullRelationship == thisOption.fullRelationship }) )

        //Update display on object
        vm.$set(vm.grouped[key].options[updateIndex], 'display', true)

        // Remove selected option from value
        vm.dataFields.value.splice(index, 1);

        //console.log( updateKey )
      },
      /**
      * Drag and Drop Begin
      **/
      dragStart(which, ev) {
        let vm = this

        ev.dataTransfer.setData('Text', vm.id);
        ev.dataTransfer.dropEffect = 'move'
        vm.dragging = which;
      },
      dragEnter(ev) {
        // Do something
      },
      dragLeave(ev) {
        // Do something
      },
      dragEnd(ev) {
        let vm = this

        vm.dragging = -1
      },
      dragFinish(to, ev) {
        let vm = this

        vm.moveItem(vm.dragging, to);
        //ev.target.style.marginTop = '2px'
        //ev.target.style.marginBottom = '2px'
      },
      moveItem(from, to) {
        let vm = this

        if (to === -1) {
          vm.removeItemAt(from);
        } else {
          vm.dataFields.value.splice(to, 0, vm.dataFields.value.splice(from, 1)[0]);
        }
      },
      removeItemAt(index) {
        let vm = this

        vm.dataFields.value.splice(index, 1);
      },
      /**
      * Drag and Drop End
      **/
      goBack(){
        Bus.$emit('back', true);
      }
    },
    components:{
      'vue-loader':     require('../../loader/loader.vue').default,
    }
}
</script>
