<template>
  <div v-if="show" :class="['loader-wrapper',{'loader-overlay' : overlay}]">
    <div class="spinner">
      <div class="dot1"></div>
      <div class="dot2"></div>
    </div>
    <div v-if="message" class="loader-text">
      <span class="loader-blink">{{ message | translate }}</span>
    </div>
  </div>
</template>

<style>

</style>

<script>
export default{
    template: '<vue-loader></vue-loader>',
    props: {
      show: {
            type: Boolean,
            'default': false
      },
      message: {
          type: String,
          'default': 'loading'
      },
      overlay: {
            type: Boolean,
            'default': false
      },
    },
    data(){
        return{
        }
    },
    created() {

    },
    mounted(){

    },
    computed:{

    },
    watch:{

    },
    methods:{

    },
    components:{

    }
}
</script>
