<template>
  <div>
    <div>
      <v-server-table :columns="columns" :options="options"></v-server-table>
    </div>
  </div>
</template>

<style>

</style>

<script>
export default{
    template: '<report-list></report-list>',
    props: [],
    data(){
      return{
        columns: ['name', 'model_name', 'created_at','updated_at','actions'],
        options: {
          headings: {
            name: 'Name',
            model_name: 'Report Type',
            created_at: 'Date Created',
            updated_at: 'Last Edited',
              actions: ''
          },
          sortable: [],
          filterable: false,
          pagination: { nav: 'fixed',edge: false },
          perPage:10,
          perPageValues:[10],
          requestFunction: function (data) {
              let vm = this;

              return vm.axios.get(`/report-generator/list?page=${data.page}&perPage=${data.limit}`)
              .catch(function (e) {
                  this.dispatch('error', e);
              }.bind(this));
          },
          responseAdapter({data}) {
            return {
              data: data.reports.data,
              count: data.reports.total
            }
          },
          templates: {
            actions:    'buttons-template',
            created_at: 'created-template',
            updated_at: 'updated-template'
          }
        }
      }
    },
    created() {

    },
    mounted(){
      //this.testData()

    },
    computed:{

    },
    watch:{

    },
    methods:{

    },
    components:{

    }
}
/**
* Extra Component for Last Updated Date Format inside of Datatables
**/
Vue.component('updated-template', {
  props: ['data', 'index', 'column'],
  template: `<span>{{ formatDate }}</span>`,
  computed: {
    formatDate(){
      return Moment(this.data.updated_at).format('YYYY-MM-DD')
    }
  }
});
/**
* Extra Component for Created Date Format inside of Datatables
**/
Vue.component('created-template', {
  props: ['data', 'index', 'column'],
  template: `<span>{{ formatDate }}</span>`,
  computed: {
    formatDate(){
      return Moment(this.data.created_at).format('YYYY-MM-DD')
    }
  }
})
/**
* Extra Component for Buttons inside of Datatables
**/
Vue.component('buttons-template', {
    props: ['data', 'index', 'column'],
    template: `<div class="text-right">
                  <a data-toggle="tooltip" data-title="View" :href="'/report-generator/details/' + data.id" class="cursor view-button action-button"><img class="action-icon" src="/images/action/action_icon_play.png" alt="View"></a>
                  <a data-toggle="tooltip" data-title="Delete" @click.prevent="removeReport()" class="cursor delete-button action-button"><img class="action-icon" src="/images/action/action_icon_delete_new.png" alt="Delete" data-title="Delete"></a>
                  <a data-toggle="tooltip" data-title="Rename" @click.prevent="renameReport()" class="cursor rename-button action-button"><img class="action-icon" src="/images/action/action_icon_rename.png" alt="Rename" data-title="Rename"></a>
                  <a data-toggle="tooltip" data-title="Modify Filters" @click.prevent="editReport()" class="cursor edit-button action-button"><img class="action-icon" src="/images/action/action_icon_edit_new.png" alt="Edit" data-title="Modify Filters"></a>
              </div>`,
    methods: {
        removeReport(){
          /**
          * Notify Parent to Soft Delete ( 24hrs before removal / hides from menu listing )
          **/
          const remove = { edit: false, remove: true, rename: false, processing: false, report: this.data }

          Bus.$emit('removereport', remove )
        },
        editReport(){
          /**
          * Notify top level to load report for Editing
          **/
          const edit = { edit: true, remove: false, rename: false, processing: false, report: this.data }

          Bus.$emit('editreport', edit )
        },
        renameReport(){
          /**
          * Notify top level to load report for Editing
          **/
          const edit = { edit: false, remove: false, rename: true, processing: false, report: this.data }

          Bus.$emit('renamereport', edit )
        }
    },
    mounted(){
        $('[data-toggle="tooltip"]').tooltip();
        $('.delete-button').hover(function () {
            $(this).find('img').prop('src', '/images/action/action_icon_delete_active.png');
        }, function () {
            $(this).find('img').prop('src', '/images/action/action_icon_delete_new.png');
        });

        $('.rename-button').hover(function () {
            $(this).find('img').prop('src', '/images/action/action_icon_rename_active.png');
        }, function () {
            $(this).find('img').prop('src', '/images/action/action_icon_rename.png');
        });

        $('.edit-button').hover(function () {
            $(this).find('img').prop('src', '/images/action/action_icon_edit_active.png');
        }, function () {
            $(this).find('img').prop('src', '/images/action/action_icon_edit_new.png');
        });

        $('.view-button').hover(function () {
            $(this).find('img').prop('src', '/images/action/action_icon_play_active.png');
        }, function () {
            $(this).find('img').prop('src', '/images/action/action_icon_play.png');
        });
    },
});
</script>
