window.guild = {
  select2AjaxData(params) {
    if (_.has(window.Laravel, 'api_token')) {
      const query = {
        api_token: window.Laravel.api_token,
      };
      return Object.assign(query, params);
    }
    return params;
  },
  updateRepeaterItems(currentTarget) {
    $('.form-group', currentTarget).each(function updateItem() {
      const label = $('label[for]', this);
      const input = $('input[name][id], select[name][id]', this);
      input.prop('id', input.prop('name'));
      label.prop('for', input.prop('id'));
    });
  },
};
