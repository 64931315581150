<template>
  <vue-loader :show="loading.isloading" :message="loading.message" :overlay="true"></vue-loader>
</template>

<style>

</style>

<script>
/**
* --- Notes ---
* The rules stored vs rules active durring form usage are not the same,
* While using the form, we track more details about each rule to facilitate
* the natural language format of the form.
*
* Here we will set all stored report variables in form to match full details required.
*
* examlpe: we store country id, durring form usage country is full object
**/
export default{
    template: '<report-edit-prep></report-edit-prep>',
    props: ['report','dataModels','dataFields','dataRules'],
    data(){
        return{
          fieldsGrouped: [],
          loading: {
            isloading: true,
            message: 'Preparing Data'
          }
        }
    },
    created(){

    },
    mounted(){
      this.fetchModels()
    },
    computed:{

    },
    watch:{

    },
    methods:{
/**
* ----------------------------------------------------
* Prep Models
* ----------------------------------------------------
**/
      fetchModels: _.throttle( function () {
          let vm = this

          vm.$set(vm.loading,'message','Processing Models')

          // Get Actual data via axios
          vm.axios.get('/report-generator/reportable-models')
          .then( response => {
            //vm.modelOptions.push.apply(vm.modelOptions, response.data)

            vm.$set(vm.dataModels,'value',response.data.find(function (obj) { return obj.model === vm.report.model_name }) )
            vm.$set(vm.dataModels,'finished', true )
            //console.log(response.data)

            vm.fetchFields()
          }).catch( error => {
            vm.prepError( error )
          });
      },400 ),

/**
* ----------------------------------------------------
* Process Fields
* ----------------------------------------------------
**/
      /**
      * Prep Fields
      **/
      fetchFields: _.throttle( function () {
        let vm = this,
            tmpModel = vm.dataModels.value.model

        vm.$set(vm.loading,'message','Loading Fields')
        //Clear Data
        vm.$set(vm.fieldsGrouped,[])

        const tempObject = {
                model: tmpModel,
                options: []
              }

        // Get Actual data via axios
        vm.axios.get('/report-generator/reportable-fields/' + tmpModel)
        .then( response => {
          tempObject.options.push.apply(tempObject.options, response.data.filters)
          vm.fieldsGrouped.push(tempObject)

          if(response.data.relationships){
            vm.fetchRelations()
          }else{
            vm.proccessfields()
          }
        }).catch( error => {
          vm.prepError( error )
        });
      },400 ),
      /**
      * Prep Field Relationships
      **/
      fetchRelations: _.throttle( function () {
        let vm = this,
            tmpModel = vm.dataModels.value.model

        vm.$set(vm.loading,'message','Loading Relationships')
        // Get relationships
        vm.axios.get('/report-generator/all-related-fields/' + tmpModel)
        .then( response => {
          Object.entries(response.data).forEach(([key, val]) => {
            const tempObject = {
                    model: val.model,
                    options: []
                  }

            tempObject.options.push.apply(tempObject.options, val.filters)
            vm.fieldsGrouped.push(tempObject)
          })

          vm.proccessfields()
        }).catch( error => {
          vm.prepError( error )
        })
      },400 ),
      /**
      * Set Exisitng Field objects
      **/
      proccessfields(){
        let vm = this

        vm.$set(vm.loading,'message','Configuring Fields')

        for (var i=0; i < vm.report.filters.length; i++) {
          const fullR = vm.report.filters[i].fullRelationship
          const model = vm.report.filters[i].model

          const key = vm.fieldsGrouped.indexOf( vm.fieldsGrouped.find(function (obj) { return obj.model === model }) )
          if( key > -1 ){
            vm.dataFields.value.push(vm.fieldsGrouped[key].options.find(function (obj) { return obj.fullRelationship === fullR }) )
          }
        }

        vm.$set(vm.dataFields,'finished', true )
        vm.processRules(0) // Start at begining
      },
/**
* ----------------------------------------------------
* Process Rules
* ----------------------------------------------------
**/
      processRules( i ){
        /**
        * Check each filter in report if a rule exists and then proccess said rule.
        **/
        let vm = this

        vm.$set(vm.loading,'message',`Processing Rules ${i + 1} of ${vm.report.filters.length + 1}`)

        if( i < vm.report.filters.length ){
          vm.processRulesWait( i )
        }else{
          Bus.$emit('finishedEditLoading', true);
          vm.$set(vm.loading,'message',`Finished Processing`)
        }
      },
      processRulesWait( i ){
        /**
        * We don't set a until each variant has completed. Work around for aysnc promise across various browsers.
        **/
          let vm = this

          const randomId = Math.floor(Date.now() / 1000) + '-' + Math.random().toString(36).substr(2, 9); // Need key for splice issue

          if(vm.report.filters[i].rule !== null){
            vm.dataRules.check.push(vm.report.filters[i].fullRelationship)

            const fullR = vm.report.filters[i].fullRelationship

            // Build Rule and Set Basic Values
            let newRule = {
                id: randomId,
                model: vm.report.filters[i].model,
                field: vm.report.filters[i].field,
                fullRelationship: vm.report.filters[i].fullRelationship,
                operation: vm.report.filters[i].rule.operation,
                input: null,
                state: 'active',
                loading: false
              }

            // Format Value for editing
            const tmpPrams = vm.dataFields.value.find(function (obj) { return obj.fullRelationship === fullR })

            // Shorten Names for easier tracking durring this proccess
            const inputtype = tmpPrams.rules.inputtype
            const formtype  = tmpPrams.rules.formtype
            const operation = newRule.operation
            const reportRule = vm.report.filters[i].rule.value

            if(inputtype == 'multiselect' || inputtype == 'select'){
              // Get Actual data via axios
              vm.axios.get('/' + tmpPrams.rules.selectdata + '/list')
              .then( response => {

                if( inputtype == 'select' ){ // Single Selected item
                  vm.$set(newRule,'input', response.data.find(function (robj) { return robj.id == reportRule }) )
                }else{ // Multiselect and possible multipule values
                  vm.$set(newRule,'input',[])

                  for (var si=0; si < reportRule.length; si++) {
                      newRule.input.push( response.data.find(function (robj) { return robj.id == reportRule[si] }) )
                  }
                }

                vm.dataRules.value.push(newRule)

                const a = i + 1 // typical ++ was failing
                vm.processRules( a )
              }).catch( error => {
                // Preform Error Function
                const a = i + 1 // typical ++ was failing
                vm.processRules( a )
              });
            }else if(inputtype == 'date' || inputtype == 'date'){
              // Convert Date
              if( operation == 'between'){
                const date1 = new Date(vm.report.filters[i].rule.value.first)
                const date2 = new Date(vm.report.filters[i].rule.value.second)
                vm.$set(newRule,'input',{'first': date1,'second': date2})
              }else{
                const date1 = new Date(vm.report.filters[i].rule.value)
                vm.$set(newRule,'input', date1 )
              }
              vm.dataRules.value.push(newRule)

              const a = i + 1 // typical ++ was failing
              vm.processRules( a )
            }else{
              // All other types can have values set straight from report stored to value
              vm.$set(newRule,'input',vm.report.filters[i].rule.value)
              vm.dataRules.value.push(newRule)

              const a = i + 1 // typical ++ was failing
              vm.processRules( a )
            }

          }else{
            // No Actual Rule move to next Filter
            const a = i + 1 // typical ++ was failing
            vm.processRules( a )
          }

      },

/**
* ----------------------------------------------------
* Catch All Errors
* ----------------------------------------------------
**/
      prepError( error ){
        let vm = this
        vm.$set(vm.loading,'isloading',false)
      }
    },
    components:{
      'vue-loader':     require('../../loader/loader.vue').default,
    }
}
</script>
