<template>
    <div class="row">

      <!--<div class="col-md-12">-->
        <!--<h1 class="text-center">{{ 'Report builder' | translate }}</h1>-->
      <!--</div>-->

      <!--<div class="col-md-12"><hr></div>-->
<!--      <div class="col-md-12 text-center">-->
<!--        <button type="button" class="btn btn-primary btn-report custom">Build a New Custom Report</button>-->
<!--      </div>-->

<!--      <div class="row col-md-12" id="default-reports">-->
<!--        <div class="col-md-6">-->
<!--          <div class="panel panel-default">-->
<!--            <div class="panel-body">-->
<!--              <div class="col-sm-12">-->
<!--                <h4 class="profile">MailChimp Export</h4>-->
<!--              </div>-->
<!--                <div class="col-sm-6 text-left"><a href="admin/reports/mailchimp/license">By License Status</a></div>-->
<!--                <div class="col-sm-6 text-left"><a href="admin/reports/view/18">Facility Owners</a></div>-->
<!--                <div class="col-sm-12"><hr/></div>-->
<!--                <div class="col-sm-6 text-left"><a href="admin/reports/view/19">French Service Providers</a></div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="col-md-6">-->
<!--          <div class="panel panel-default">-->
<!--            <div class="panel-body">-->
<!--              <div class="col-sm-12">-->
<!--                <h4 class="profile">Membership</h4>-->
<!--              </div>-->

<!--                <div class="col-sm-6 text-left"><a href="admin/reports/view/16">Volunteering</a></div>-->
<!--                <div class="col-sm-6 text-left"><a href="admin/reports/view/4">Member List</a></div>-->
<!--                <div class="col-sm-12"><hr/></div>-->
<!--                <div class="col-sm-6 text-left"><a href="admin/reports/view/8">Expired Members</a></div>-->
<!--                <div class="col-sm-6 text-left"><a href="admin/reports/view/9">Due Renewals</a></div>-->
<!--                <div class="col-sm-12"><hr/></div>-->
<!--                <div class="col-sm-6 text-left"><a href="admin/reports/view/15">Statistics Snapshots</a></div>-->
<!--                <div class="col-sm-6 text-left"><a href="admin/reports/view/17">Change Snapshots</a></div>-->


<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="col-md-6">-->
<!--          <div class="panel panel-default">-->
<!--            <div class="panel-body">-->
<!--              <div class="col-sm-12">-->
<!--                <h4 class="profile">Employment</h4>-->
<!--              </div>-->

<!--              <div class="col-sm-6 text-left"><a href="admin/reports/view/14">Employer Review</a></div>-->

<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="col-md-6">-->
<!--          <div class="panel panel-default">-->
<!--            <div class="panel-body">-->
<!--              <div class="col-sm-12">-->
<!--                <h4 class="profile">Government Reports</h4>-->
<!--              </div>-->

<!--              <div class="col-sm-6 text-left"><a href="admin/reports/view/10">DHWNS Report</a></div>-->
<!--              <div class="col-sm-6 text-left"><a href="admin/reports/view/3">CIHI Report</a></div>-->

<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--      </div>-->

      <div id="custom-reports">
        <div class="col-md-12">
          <p class="text-center page-sub-title">{{ 'rb-models-title' | translate}}</p>
          <p class="text-center">{{ 'rb-models-content' | translate }}</p>
        </div>

        <div class="col-md-12">
          <div class="form-group">
            <vue-loader :show="loading.isloading" :message="loading.message"></vue-loader>

            <div v-if="!loading.isloading" class="text-center">
              <div v-for="(option, key) in options" :class="['report-radio','inline-radio']" >
                <input type="radio" :class="['hidden']" :id="'modelsOption-' + key" :value="option" v-model="dataModels.value">
                <label :for="'modelsOption-' + key" class="report-label cursor">
                  <div :class="['btn btn-xl center-block',{'btn-success btn-active-circle': option == dataModels.value, 'btn-link btn-circle': option != dataModels.value}]">
                    <img :src="'/images/dashboard/' + option.icon" :class="['dashboard_icon', option.name]">
                  </div>
                  <p class="dashboard-item">{{ option.name | translate }}</p>
                </label>
              </div>
              <div class="clearfix"></div>

            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12"><hr class="report-divider"></div>

      <div v-if="savedReports" class="col-md-12">
        <p class="text-left page-sub-title">{{ 'Your Saved Custom Reports' | translate }}</p>
        <vue-loader :show="dataRemove" :message="'Removing Report'"></vue-loader>
        <div v-if="!dataRemove">
          <report-list v-if="savedReports"></report-list>
        </div>
      </div>
      <div v-if="!savedReports" class="col-md-12">
        <p class="text-left page-sub-title">{{ 'You currently have no saved reports...' | translate }}</p>
      </div>
    </div>

</template>

<style>

</style>

<script>
/**
<report-list></report-list>

<div>
  <button :disabled="!dataModels.value" @click.prevent="dataModels.finished = 1" type="button" :class="['btn btn-primary btn-lg pull-right']">{{ 'Next Step' | translate }} <span class="glyphicon glyphicon-circle-arrow-right bc-pl" aria-hidden="true"></span></button>
  <div class="clearfix"></div>
</div>
**/
export default{
    template: '<report-models></report-models>',
    props: ['dataModels','dataRemove','validation'],
    data(){
        return{
          options: [],
          loading: {
            isloading: false,
            message: '...Loading Models'
          },
          savedReports: false
        }
    },
    created(){
      history.pushState(null, null, location.href)
    },
    mounted(){
      this.scrollToTop();
      this.fetch();
        // let custom = false;
        // $('.custom').on('click', function () {
        //     if (custom == false) {
        //         $('#default-reports').hide();
        //         $('#custom-reports').show();
        //         $(this).text('Show Default Reports');
        //         custom = true;
        //     }
        //     else {
        //         $('#default-reports').show();
        //         $('#custom-reports').hide();
        //         $(this).text('Build a New Custom Report');
        //         custom = false;
        //     }
        // });
    },
    computed:{
      hasError() {
        return (this.validation.errors['models'] ? true : false )
      }
    },
    watch:{
      'dataModels.value': function(val, oldVal){
        this.dataModels.finished = true
      }
    },
    methods:{
      scrollToTop(){
        $('html,body').stop().animate({
          scrollTop: 0
        }, 'slow', 'swing');
      },
      fetch: _.throttle( function () {
          let vm = this

          vm.$set(vm.loading,'isloading',true)
          // Get Actual data via axios
          vm.axios.get('/report-generator/reportable-models')
          .then( response => {
            vm.options.push.apply(vm.options, response.data)
            vm.checkSavedReports()
            //vm.$set(vm.loading,'isloading',false)
          }).catch( error => {
            vm.validation.record(error.response.data)
            vm.$set(vm.loading,'isloading',false)
            // Preform Error Function
          });
      },400 ),
      /**
      * Are there Saved Reports?
      * Determines if Saved Reports Table should be Displayed
      **/
      checkSavedReports:  _.throttle( function () {
        let vm = this

        vm.$set(vm.loading,'message','Checking For Saved Reports')

        vm.$set(vm.loading,'isloading',true)
        // Get Actual data via axios
        vm.axios.get(`/report-generator/list?page=1&perPage=10`)
        .then( response => {
          vm.savedReports = (response.data.reports.data.length > 0 ? true : false)
          vm.$set(vm.loading,'isloading',false)
        }).catch( error => {
          vm.validation.record(error.response.data)
          vm.$set(vm.loading,'isloading',false)
        });
      },400 ),
    },
    components:{
      'report-list':    require('./list.vue').default,
      'vue-loader':     require('../../loader/loader.vue').default,
    }
}
</script>
