/**
* Scaffolding Structure to manage data for Report Builder
* This provides a scaffolding to keep track of various Options
* and data durring the form proccess
**/
import Errors from './errors.js'

class Form {

  constructor(){
    let scaffolding = this.formScaffolding();
    scaffolding['validation'] = new Errors();
    return scaffolding
  }

  formScaffolding(){
    const scaffolding = {
      models: { // Sample Model List
        label: 'Avalible Modals',
        required: true,
        value: null,
        finished: false,
      },
      fields: { // Sample Model List
        label: 'Avalible Fields',
        required: true,
        value: [],
        finished: false,
      },
      rules: { // Sample Model List
        label: 'Set Rules',
        required: true,
        value: [],
        check:[], // Added to help quickly filter duplicate rule use.
        finished: false,
      }
    }

    return scaffolding
  }

}

export default Form;
